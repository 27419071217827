import jQuery from 'jquery'
import { marked } from 'marked'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { mapGetters } from 'vuex';

var long_months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
var short_months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

export default {
	emits: ["reload", "sorted"],
	data()
	{
		return {
			editing: false,
			dev: String(window.location).indexOf("dev.easyonnet.io") !== -1,
			local: String(window.location).indexOf("local.easyonnet.io") !== -1,
			api_server: "https://" + (String(window.location).indexOf("dev.easyonnet.io") !== -1 || String(window.location).indexOf("local.easyonnet.io") !== -1 ? "apidev" : "api") + ".easyonnet.io",
			// api_server: "https://local.easyonnet.io:9999",
			loading_count: 0,
			client: {"client_id": null},
			editor: Editor,
			editorConfig: {},
		}
	},
	computed:
	{
		sort_label: function()
		{
			for(var i=0; i<this.sorting.length; i++)
			{
				if(this.sorting[i].field == this.sort_field && this.sorting[i].dir == this.sort_dir)
				{
					return this.sorting[i].label;
				}
			}

			return "";
		},
		...mapGetters([
			"loading",
		])
	},
	methods:
	{
		CORS(request_type, request_url, request_data, success_callback, fail_callback, custom_xhr)
		{
			var vm = this;

			if(typeof custom_xhr == "undefined" || custom_xhr == null)
			{
				custom_xhr = function() { return new XMLHttpRequest() };
			}

			return jQuery.ajax(
			{
				type: request_type,
				url: vm.api_server + request_url,
				cache: false,
				data: request_data,
				xhr: custom_xhr,
				xhrFields: {
					withCredentials: true
				},
				crossDomain: true,
				beforeSend: function(xhr) {
					xhr.setRequestHeader("Content-type", "application/json");
					xhr.setRequestHeader("X-CSRF-TOKEN", vm.getCookie("csrf_access_token"));
				}
			}).done(function(data)
			{
				if(typeof success_callback == "function")
				{
					success_callback(data);
				}
			}).fail(function(jqXHR, textStatus, errorThrown)
			{
				if(jqXHR.status == 401 || jqXHR.status == 403)
				{
					// If the 403 is accessing a node, ignore it so the node page can deal with it.
					if(jqXHR.status == 403 && window.location.pathname.match(/^\/node\/([0-9]+)$/))
					{
						if(typeof fail_callback == "function")
						{
							fail_callback(jqXHR);
						}

						return;
					}

					if(vm.$route.path != "/login")
					{
						vm.closeModals();
						vm.$router.push("/login").catch(function() { /* ignore error */ });
					}
				}
				else
				{
					if(typeof fail_callback == "function")
					{
						fail_callback(jqXHR);
					}
				}
			});
		},
		CORS2(request_type, request_url, request_data, success_callback, fail_callback, custom_xhr)
		{
			var vm = this;

			if(typeof custom_xhr == "undefined" || custom_xhr == null)
			{
				custom_xhr = function() { return new XMLHttpRequest() };
			}

			return jQuery.ajax(
			{
				type: request_type,
				url: vm.api_server + request_url,
				cache: false,
				data: request_data,
				xhr: custom_xhr,
				xhrFields: {
					withCredentials: true
				},
				crossDomain: true,
				beforeSend: function(xhr) {
					// xhr.setRequestHeader("Content-type", "multipart/form-data");
					xhr.setRequestHeader("X-CSRF-TOKEN", vm.getCookie("csrf_access_token"));
				},
				processData: false,  // tell jQuery not to process the data
				contentType: false,  // tell jQuery not to set contentType
			}).done(function(data)
			{
				if(typeof success_callback == "function")
				{
					success_callback(data);
				}
			}).fail(function(jqXHR, textStatus, errorThrown)
			{
				if(jqXHR.status == 401 || jqXHR.status == 403)
				{
					// If the 403 is accessing a node, ignore it so the node page can deal with it.
					if(jqXHR.status == 403 && window.location.pathname.match(/^\/node\/([0-9]+)$/))
					{
						if(typeof fail_callback == "function")
						{
							fail_callback(jqXHR);
						}

						return;
					}

					if(vm.$route.path != "/login")
					{
						vm.$router.push("/login").catch(function() { /* ignore error */ });
					}
				}
				else
				{
					if(typeof fail_callback == "function")
					{
						fail_callback(jqXHR);
					}
				}
			});
		},
		loadClient: function(client_node_id)
		{
			if(typeof client_node_id == "undefined" || client_node_id == null || client_node_id == 0)
			{
				return;
			}

			var vm = this;
			vm.client = this.getCache("client_" + client_node_id);

			if(vm.client != null)
			{
				return;
			}

			vm.$store.commit("loading");

			this.CORS('GET', "/nodes/" + client_node_id, null,
				function(data)
				{
					vm.$store.commit("done");
					vm.setCache("client_" + client_node_id, data);
					vm.client = data;
				}, function()
				{
					vm.$store.commit("done");
					vm.showError("Error", "Error loading client.", true, null);
				});
		},
		quickTask: function()
		{
			var vm = this;

			vm.nerivonInput("Quick Task", "This task will be associated with this project.", false, function(text)
			{
				if(text === false)
				{
					return;
				}

				if(text == "")
				{
					vm.showError("Nothing Entered", "You must enter a task name.", true, null);
				}
				else
				{
					vm.$store.commit("bg_loading");

					vm.CORS("POST", "/nodes", JSON.stringify({"node_type": "task", "task": text, "user_node_id": vm.user.node_id}),
						function(data)
						{
							vm.$store.commit("bg_done");
							vm.$router.push("/node/" + data.node_id);
						}, function()
						{
							vm.$store.commit("bg_done");
							vm.showError("Error", "Error creating task.", true, null);
						});
				}
			});
		},
		checkAll: function(e)
		{
			var obj = jQuery("#" + e.target.id);
			var checked = obj.prop("checked");

			if(checked)
			{
				jQuery(".bulk").prop("checked", true);
			}
			else
			{
				jQuery(".bulk").prop("checked", false);
			}
		},
		close: function()
		{
			this.$router.go(-1);
		},
		archive: function(node, redirect)
		{
			var vm = this;

			vm.nerivonConfirm("Are you sure?", "This node will be <b>ARCHIVED</b>.", "warning", true, function(confirmed)
			{
				if(confirmed)
				{
					vm.$store.commit("loading");

					vm.CORS("PUT", "/nodes/" + node.node_id + "/archive", null,
						function(data)
						{
							vm.$store.commit("done");
							vm.invalidateCaches();
							node.active = 0;

							if(redirect)
							{
								vm.$router.go(-1);
							}
							else
							{
								vm.$emit("reload");
							}
						}, function()
						{
							vm.$store.commit("done");
							vm.showError("Error", "Error archiving node.", true, null);
						});
				}
			});
		},
		unarchive: function(node, redirect)
		{
			var vm = this;

			vm.nerivonConfirm("Are you sure?", "This node will be <b>RETRIEVED FROM THE ARCHIVES</b>.", "warning", true, function(confirmed)
			{
				if(confirmed)
				{
					vm.$store.commit("loading");

					vm.CORS("PUT", "/nodes/" + node.node_id + "/unarchive", null,
						function(data)
						{
							vm.$store.commit("done");
							vm.invalidateCaches();
							node.active = 1;

							if(redirect)
							{
								vm.$router.go(-1);
							}
							else
							{
								vm.$emit("reload");
							}
						}, function()
						{
							vm.$store.commit("done");
							vm.showError("Error", "Error retrieving node.", true, null);
						});
				}
			});
		},
		rm: function(node, redirect)
		{
			var vm = this;

			vm.nerivonConfirm("Are you sure?", "This node will be <b style='color: red'>DELETED FOREVER</b>.", "warning", true, function(confirmed)
			{
				if(confirmed)
				{
					vm.$store.commit("loading");

					vm.CORS("DELETE", "/nodes/" + node.node_id, null,
						function(data)
						{
							vm.$store.commit("done");
							vm.invalidateCaches();

							if(redirect)
							{
								vm.$router.go(-1);
							}
							else
							{
								vm.$emit("reload");
							}
						}, function()
						{
							vm.$store.commit("done");
							vm.showError("Error", "Error deleting node.", true, null);
						});
				}
			});
		},
		pin: function(node, board)
		{
			var vm = this;

			if(board == null)
			{
				vm.nerivonInput("New Board", "What would you like to call it?", true, function(text)
				{
					if(text !== false && text !== "")
					{
						vm.$store.commit("loading");

						vm.CORS("POST", "/boards", JSON.stringify({title: text}),
							function(board)
							{
								vm.$store.commit("done");
								vm.boards.push(board);
								vm._pin(node, board);
							}, function()
							{
								vm.$store.commit("done");
								vm.showError("Error", "Error creating board.", true, null);
							});
					}
				})
			}
			else
			{
				return vm._pin(node, board);
			}
		},
		_pin: function(node, board)
		{
			var vm = this;
			var pin = (!vm.pinned(node.node_id, board));

			vm.$store.commit("loading");

			vm.CORS("PUT", "/nodes/" + node.node_id + "/pin/" + board.board_id + "/" + (pin ? 1 : 0), null,
				function(data)
				{
					if(pin)
					{
						board.nodes.push(node);
					}
					else
					{
						for(var i=0; i<board.nodes.length; i++)
						{
							if(board.nodes[i].node_id == node.node_id)
							{
								board.nodes.splice(i, 1);
								break;
							}
						}
					}

					vm.$store.commit("done");
					vm.invalidateCaches();
				}, function()
				{
					vm.$store.commit("done");
					vm.showError("Error", "Error pinning node.", true, null);
				});
		},
		pinned: function(node_id, board)
		{
			if(board == null)
			{
				return false;
			}

			for(var i=0; i<board.nodes.length; i++)
			{
				if(board.nodes[i].node_id == node_id)
				{
					return true;
				}
			}

			return false;
		},
		sortNodeUp: function()
		{
			this.node.sort -= 1.1;

			if(this.node.sort < 0)
			{
				this.node.sort = 0;
			}

			this.$emit("sorted", this.node.node_id);
		},
		sortNodeDown: function()
		{
			this.node.sort += 1.1;
			this.$emit("sorted", this.node.node_id);
		},
		dateFormat(obj)
		{
			var dt = new Date(obj);
			var y = dt.getFullYear();
			var m = dt.getMonth() + 1;
			var d = dt.getDate();

			if(m < 10)
			{
				m = "0" + m;
			}
			if(d < 10)
			{
				d = "0" + d;
			}
			return y + "-" + m + "-" + d;
		},
		dateTimeFormat(obj)
		{
			var dt = new Date(obj);
			var h = dt.getHours();
			var m = dt.getMinutes();

			if(h < 10)
			{
				h = "0" + h;
			}
			if(m < 10)
			{
				m = "0" + m;
			}
			return this.dateFormat(obj) + " at " + h + ":" + m;
		},
		setCookie(cname,cvalue,exdays)
		{
			var d = new Date();
			d.setTime(d.getTime()+(exdays*24*60*60*1000));
			var expires = "expires="+d.toGMTString();
			document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/;SameSite=Strict";
		},
		getCookie(cname)
		{
			var name = cname + "=";
			var ca = document.cookie.split(';');
			for(var i=0; i<ca.length; i++)
			{
				var c = ca[i].trim();
				if (c.indexOf(name)==0)
					return c.substring(name.length,c.length);
			}
			return "";
		},
		closeModals: function()
		{
			jQuery(".modal").each(function()
			{
				var me = jQuery(this);

				if(me.hasClass("fade"))
				{
					me.removeClass("fade");
					me.modal("hide");
					me.addClass("fade");
				}
				else
				{
					me.modal("hide");
				}
			});
		},
		nerivonConfirm: function(title, message, type, close_after, callback)
		{
			// var vm = this;
			var icon = "";

			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function() {};
			}

			if(type == "warning")
			{
				icon = '<em class="fas fa-exclamation-circle text-warning"></em>';
			}
			else if(type == "error")
			{
				icon = '<em class="fas fa-exclamation-circle text-danger"></em>';
			}
			else if(type == "success")
			{
				icon = '<em class="fas fa-check-circle text-success"></em>';
			}
			else if(type == "info")
			{
				icon = '<em class="fas fa-info-circle text-info"></em>';
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title">' + icon + ' ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '</div> \
						<div class="modal-footer text-center"> \
							<input type="hidden" id="nvModalAnswer" value="" /> \
							<button type="button" class="btn btn-secondary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 0">No</button> \
							<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 1">Yes</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			var vm = this;

			jQuery('#nvModal').on('shown.bs.modal', function ()
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', function ()
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				// Make sure all modals are closed. Sometimes the backdrop gets stuck.
				vm.closeModals();
				callback((nvModalAnswer == 1 ? true : false));
			});

			jQuery("#nvModal").modal("show");
		},
		nerivonAlert: function(title, message, type, close_after, callback)
		{
			// var vm = this;
			var icon = "";

			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function(isConfirm)
				{
					return isConfirm;
				};
			}

			if(type == "warning")
			{
				icon = '<em class="fas fa-exclamation-circle text-warning"></em>';
			}
			else if(type == "error")
			{
				icon = '<em class="fas fa-exclamation-circle text-danger"></em>';
			}
			else if(type == "success")
			{
				icon = '<em class="fas fa-check-circle text-success"></em>';
			}
			else if(type == "info")
			{
				icon = '<em class="fas fa-info-circle text-info"></em>';
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title">' + icon + ' ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '</div> \
						<div class="modal-footer text-center"> \
							<input type="hidden" id="nvModalAnswer" value="" /> \
							<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 1">Ok</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', function ()
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', function ()
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				callback((nvModalAnswer == 1 ? true : false));
			});

			jQuery("#nvModal").modal("show");
		},
		nerivonInput: function(title, message, close_after, callback)
		{
			// var vm = this;
			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function() {};
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title"><em class="fas fa-keyboard"></em> ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '<br><br><input id="nvModalInput" class="form-control" autofocus /></div> \
						<div class="modal-footer text-center"> \
							<button type="button" class="btn btn-secondary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 0">Cancel</button> \
							<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = document.getElementById(\'nvModalInput\').value">Ok</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', function ()
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', function ()
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				callback((nvModalAnswer == 0 ? false : nvModalAnswer));
			});

			jQuery("#nvModal").modal("show");
		},
		nerivonChoice: function(title, message, type, choices, close_after, callback)
		{
			// var vm = this;
			var icon = "";
			var choices_html = "";

			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function() {};
			}

			if(type == "warning")
			{
				icon = '<em class="fas fa-exclamation-circle text-warning"></em>';
			}
			else if(type == "error")
			{
				icon = '<em class="fas fa-exclamation-circle text-danger"></em>';
			}
			else if(type == "success")
			{
				icon = '<em class="fas fa-check-circle text-success"></em>';
			}
			else if(type == "info")
			{
				icon = '<em class="fas fa-info-circle text-info"></em>';
			}

			for(var i=0; i<choices.length; i++)
			{
				choices_html += '<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = \'' + choices[i][0] + '\'">' + choices[i][1] + '</button>';
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title">' + icon + ' ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '</div> \
						<div class="modal-footer text-center"> \
							<input type="hidden" id="nvModalAnswer" value="" />' + choices_html + ' \
							<button type="button" class="btn btn-secondary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = \'\'">Cancel</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', function ()
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', function ()
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				callback(nvModalAnswer);
			});

			jQuery("#nvModal").modal("show");
		},
		nerivonInvoice: function(title, message, type, url, close_after, callback)
		{
			// var vm = this;
			var icon = "";

			jQuery('#nvModal').detach();

			if(callback == null)
			{
				callback = function(isConfirm)
				{
					return isConfirm;
				};
			}

			if(type == "warning")
			{
				icon = '<em class="fal fa-exclamation-circle text-warning"></em>';
			}
			else if(type == "error")
			{
				icon = '<em class="fal fa-exclamation-circle text-danger"></em>';
			}
			else if(type == "success")
			{
				icon = '<em class="fal fa-check-circle text-success"></em>';
			}
			else if(type == "info")
			{
				icon = '<em class="fal fa-info-circle text-info"></em>';
			}

			jQuery("body").prepend('<div class="modal fade" tabindex="-1" role="dialog" id="nvModal"> \
				<div class="modal-dialog modal-dialog-centered" role="document"> \
					<div class="modal-content"> \
						<div class="modal-header"> \
							<h3 class="modal-title">' + icon + ' ' + title + '</h3> \
							<button type="button" class="close" data-dismiss="modal" aria-label="Close"> \
							<span aria-hidden="true">&times;</span> \
							</button> \
						</div> \
						<div class="modal-body text-center">' + message + '</div> \
						<div class="modal-footer text-center"> \
							<input type="hidden" id="nvModalAnswer" value="" /> \
							' + (url ? '<a href="' + url + '" target="_blank" class="btn btn-success">View Invoice</a>' : '') + ' \
							<button type="button" class="btn btn-primary" data-dismiss="modal" onclick="document.getElementById(\'nvModalAnswer\').value = 1">Ok</button> \
						</div> \
					</div> \
				</div> \
			</div>');

			jQuery('#nvModal').on('shown.bs.modal', function ()
			{
				jQuery('#nvModal input[autofocus]').focus();
			});

			jQuery('#nvModal').on('hidden.bs.modal', function ()
			{
				var nvModalAnswer = document.getElementById("nvModalAnswer").value;
				jQuery('#nvModal').detach();
				callback((nvModalAnswer == 1 ? true : false));
			});

			jQuery("#nvModal").modal("show");
		},
		showWarning: function(message, text, close_after, callback)
		{
			this.nerivonConfirm(message, text, "warning", close_after, callback);
		},
		showError: function(message, text, close_after, callback)
		{
			this.nerivonAlert(message, text, "error", close_after, callback);
		},
		showSuccess: function(message, text, close_after, callback)
		{
			this.nerivonAlert(message, text, "success", close_after, callback);
		},
		showInfo: function(message, text, close_after, callback)
		{
			this.nerivonAlert(message, text, "info", close_after, callback);
		},
		showToast: function(title, subtitle, message, autohide)
		{
			jQuery("#toast_title").html(title);
			jQuery("#toast_subtitle").html(subtitle);
			jQuery("#toast_message").html(message);
			jQuery("#toast").toast({"autohide": autohide});
			jQuery("#toast").toast("show");
		},
		showToastSuccess: function(title, subtitle, message)
		{
			this.showToast("<em class='fas fa-check-circle text-success'></em> " + title, subtitle, message, true);
		},
		showToastError: function(title, subtitle, message)
		{
			this.showToast("<em class='fas fa-times-circle text-error'></em> " + title, subtitle, message, true);
		},
		generatePassword: function(length, chars="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789")
		{
			var count = chars.length;
			var result = "";
			var index = null;

			for(var i=0; i<length; i++)
			{
				index = Math.floor((Math.random() * count-1));
				result += chars.substring(index, index+1);
			}

			return result;
		},
		formatDuration: function(minutes)
		{
			if(typeof minutes != "number")
			{
				minutes = 0;
			}

			var hours = Math.floor(minutes/60);
			minutes   = minutes - (hours*60);

			if(hours == 1)
			{
				return this.number_format(hours, 0, ".", ",") + (hours == 1 ? " hour" : " hours") + (minutes > 0 ? ", " + this.number_format(minutes, 0, ".", ",") + (minutes == 1 ? " minute" : " minutes") : "");
			}
			else if(hours > 1)
			{
				return this.number_format(hours, 0, ".", ",") + (hours == 1 ? " hour" : " hours") + (minutes > 0 ? ", " + this.number_format(minutes, 0, ".", ",") + (minutes == 1 ? " minute" : " minutes") : "");
			}
			else
			{
				return this.number_format(minutes, 0, ".", ",") + (minutes == 1 ? " minute" : " minutes");
			}

			// return minutes;
		},
		copy: function(id)
		{
			const node = document.getElementById(id);

			if(document.body.createTextRange)
			{
		        const range = document.body.createTextRange();
		        range.moveToElementText(node);
		        range.select();
		    }
		    else if (window.getSelection)
		    {
		        const selection = window.getSelection();
		        const range = document.createRange();
		        range.selectNodeContents(node);
		        selection.removeAllRanges();
		        selection.addRange(range);
		    }
		    else
		    {
		        return;
		    }

			document.execCommand("copy");

			if(window.getSelection)
			{
				window.getSelection().removeAllRanges();
			}
				else if(document.selection)
			{
				document.selection.empty();
			}

			jQuery("#copy_" + id).removeClass("fa-clipboard-list").addClass("fa-check-circle text-success");

			setTimeout(function()
			{
				jQuery("#copy_" + id).removeClass("fa-check-circle text-success").addClass("fa-clipboard-list");
			}, 1000);
		},
		markdown: function(code)
		{
			if(code == null)
			{
				return "";
			}

			// Add support for Github style checkboxes.
			code = code.replace(/[-*] \[x\] (.*)/gi, '<em class="fad fa-check-circle text-success"></em> $1  ');
			code = code.replace(/[-*] \[ ?\] (.*)/g, '<em class="fal fa-circle"></em> $1  ');

			// Add support for cross linking nodes.
			code = code.replace(/{n(ode)?:([0-9]+)}/g, '<a href="/node/$2" target="_blank">node #$2</a>');
			code = code.replace(/\[n(ode)?:([0-9]+)\]/g, '<a href="/node/$2" target="_blank">node #$2</a>');
			// code = code.replace(/{n:([0-9]+)}/g, '<router-link to="/node/$1">node #$1</router-link>');
			// code = code.replace(/\[n:([0-9]+)\]/g, '<router-link to="/node/$1">node #$1</router-link>');

			return marked.parse(code, {
				renderer: new marked.Renderer(),
				gfm: true,
				tables: true,
				breaks: true,
				pedantic: false,
				sanitize: false,
				smartLists: true,
				smartypants: true,
				xhtml: true
			});
		},
		retina: function(src)
		{
			if(window.devicePixelRatio == 2)
			{
				return src.replace(/\.(png|PNG|jpe?g|JPE?G)$/, "@2x.$1");
			}
			else
			{
				return src;
			}
		},
		getIcon: function(node_type)
		{
			return "fa-fw far " + this.getRawIcon(node_type)
		},
		getRawIcon: function(node_type)
		{
			var icon = "";

			if(node_type == 'approval')
			{
				icon += "fa-question-circle";
			}
			else if(node_type == 'checklist')
			{
				icon += "fa-list";
			}
			else if(node_type == 'client')
			{
				icon += "fa-building";
			}
			else if(node_type == 'file')
			{
				icon += "fa-file";
			}
			else if(node_type == 'file_request')
			{
				icon += "fa-file-upload";
			}
			else if(node_type == 'note')
			{
				icon += "fa-edit";
			}
			else if(node_type == 'password')
			{
				icon += "fa-lock-alt";
			}
			else if(node_type == 'project')
			{
				icon += "fa-project-diagram";
			}
			else if(node_type == 'tag')
			{
				icon += "fa-tag";
			}
			else if(node_type == 'task')
			{
				icon += "fa-tasks";
			}
			else if(node_type == 'user')
			{
				icon += "fa-user";
			}
			else if(node_type == 'role')
			{
				icon += "fa-user-tag";
			}
			else if(node_type == 'rca')
			{
				icon += "fa-file-shield";
			}
			else if(node_type == 'snippet')
			{
				icon += "fa-code";
			}
			else if(node_type == 'kb')
			{
				icon += "fa-chalkboard-teacher";
			}
			else if(node_type == 'zone')
			{
				icon += "fa-atlas";
			}
			else if(node_type == 'search')
			{
				icon += "fa-search";
			}
			else if(node_type == 'zap')
			{
				icon += "fa-bolt";
			}
			else if(node_type == 'sast')
			{
				icon += "fa-user-police";
			}
			else if(node_type == 'rtfm')
			{
				icon += "fa-code";
			}
			else if(node_type == 'sbom')
			{
				icon += "fa-code-fork";
			}
			else if(node_type == 'help')
			{
				icon += "fa-info-circle";
			}
			else if(node_type == 'secrets')
			{
				icon += "fa-user-secret";
			}
			else if(node_type == 'cms')
			{
				icon += "fa-wordpress";
			}
			else if(node_type == 'guardian')
			{
				icon += "fa-shield-check";
			}
			else if(node_type == 'queue')
			{
				icon += "fa-list-check";
			}
			else if(node_type == 'scans')
			{
				icon += "fa-list-ul";
			}
			else if(node_type == 'review')
			{
				icon += "fa-user-magnifying-glass";
			}

			return icon;
		},
		nl2br: function(input)
		{
			return String(input).replace(/\n/gm, "<br>");
		},
		getCache: function(index)
		{
			var cache = sessionStorage.getItem(index);
			var cache_time = sessionStorage.getItem(index + ".expiry");

			if(cache == null || cache_time == null)
			{
				return null;
			}

			if(Number(cache_time) < Date.now() / 1000)
			{
				return null;
			}

			try
			{
				return JSON.parse(cache);
			}
			catch(e)
			{
				return null;
			}
		},
		setCache: function(index, data)
		{
			// 5 minute cache expiry.
			try
			{
				sessionStorage.setItem(index, JSON.stringify(data));
				sessionStorage.setItem(index + ".expiry", (Date.now() / 1000) + 300);
			}
			catch(e)
			{
				sessionStorage.removeItem(index);
				sessionStorage.removeItem(index + ".expiry");
			}
		},
		invalidateCaches: function(index)
		{
			sessionStorage.clear();
		},
		shortDate: function(input)
		{
			var cleaned = this.cleandate(input);

			if(cleaned != "")
			{
				var d = new Date(cleaned);
				return short_months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
			}
			else
			{
				return "N/A";
			}
		},
		shortDateTime: function(input)
		{
			var cleaned = this.cleandate(input);

			if(cleaned != "")
			{
				var d = new Date(cleaned);
				var hours = d.getHours();
				var minutes = d.getMinutes();
				var ampm = "am";

				if(hours == 12)
				{
					ampm = "pm";
				}
				else if(hours > 12)
				{
					ampm = "pm";
					hours -= 12;
				}

				if(hours < 10)
				{
					hours = "0" + hours;
				}

				if(minutes < 10)
				{
					minutes = "0" + minutes;
				}

				return short_months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear() + " at " + hours + ":" + minutes + ampm;
			}
			else
			{
				return "N/A";
			}
		},
		longDate: function(input)
		{
			var cleaned = this.cleandate(input);

			if(cleaned != "")
			{
				var d = new Date(cleaned);
				return long_months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
			}
			else
			{
				return "N/A";
			}
		},
		longDateTime: function(input)
		{
			var cleaned = this.cleandate(input);

			if(cleaned != "")
			{
				var d = new Date(cleaned);
				var hours = d.getHours();
				var minutes = d.getMinutes();
				var ampm = "am";

				if(hours == 12)
				{
					ampm = "pm";
				}
				else if(hours > 12)
				{
					ampm = "pm";
					hours -= 12;
				}

				if(hours < 10)
				{
					hours = "0" + hours;
				}

				if(minutes < 10)
				{
					minutes = "0" + minutes;
				}

				return long_months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear() + " at " + hours + ":" + minutes + ampm;
			}
			else
			{
				return "N/A";
			}
		},
		duration: function(minutes)
		{
			if(typeof minutes != "number")
			{
				minutes = 0;
			}

			var hours = Math.floor(minutes/60);
			minutes   = minutes - (hours*60);

			if(hours == 1)
			{
				return this.number_format(hours, 0, ".", ",") + (hours == 1 ? " hour" : " hours") + (minutes > 0 ? ", " + this.number_format(minutes, 0, ".", ",") + (minutes == 1 ? " minute" : " minutes") : "");
			}
			else if(hours > 1)
			{
				return this.number_format(hours, 0, ".", ",") + (hours == 1 ? " hour" : " hours") + (minutes > 0 ? ", " + this.number_format(minutes, 0, ".", ",") + (minutes == 1 ? " minute" : " minutes") : "");
			}
			else
			{
				return this.number_format(minutes, 0, ".", ",") + (minutes == 1 ? " minute" : " minutes");
			}

			// return minutes;
		},
		durationCompact: function(minutes)
		{
			if(typeof minutes != "number")
			{
				minutes = 0;
			}

			var hours = Math.floor(minutes/60);
			minutes = minutes - (hours*60);

			if(hours >= 1)
			{
				return this.number_format(hours, 0, ".", ",") + "h " + this.number_format(minutes, 0, ".", ",") + "m";
			}
			else
			{
				return this.number_format(minutes, 0, ".", ",") + "m";
			}

			// return minutes;
		},
		phoneDisplay: function(input)
		{
			var value = String(input)
			var plusone = false;

			if(value.indexOf("+1.") !== false)
			{
				plusone = true;
				value = value.replace("+1.", "");
			}

			value = value.replace(/[^0-9]/g, '');
			var length = value.length;

			if(length == 3)
			{
				// Leave value as-is.
			}
			else if(length <= 6)
			{
				value = value.replace(/([0-9]{3}?)([0-9]{1,3}?)/g, '$1-$2')
			}
			else if(length <= 10)
			{
				value = value.replace(/([0-9]{3}?)([0-9]{3}?)([0-9]{1,4}?)?/g, '$1-$2-$3')
			}
			else
			{
				value = value.replace(/([0-9]{3}?)([0-9]{3}?)([0-9]{4}?)([0-9]{1,5}?)?/g, '$1-$2-$3 x$4')
			}

			return value;
		},
		nl2br: function(input)
		{
			return String(input).replace(/\n/gm, "<br>");
		},
		relativeDate: function(input)
		{
			if(typeof input == "string")
			{
				input = new Date(input + "T09:00:00.000Z");
			}
			else if(input instanceof Date == false)
			{
				return "";
			}

			var d 		= new Date();
			var diff 	= (input.getTime() - d.getTime())/1000;
			var days 	= Math.ceil(diff/86400);
			var day 	= input.getDay();

			if(days < -1)
			{
				return (days*-1) + " days ago";
			}
			else if(days == -1)
			{
				return "Yesterday";
			}
			else if(days == 0)
			{
				return "Today";
			}
			else if(days == 1)
			{
				return "Tomorrow";
			}
			else if(days < 7)
			{
				if(day == 1)
				{
					return "Monday";
				}
				else if(day == 2)
				{
					return "Tuesday";
				}
				else if(day == 3)
				{
					return "Wednesday";
				}
				else if(day == 4)
				{
					return "Thursday";
				}
				else if(day == 5)
				{
					return "Friday";
				}
				else if(day == 6)
				{
					return "Saturday";
				}
				else if(day == 0)
				{
					return "Sunday";
				}
			}
			else if(days == 7)
			{
				if(day == 1)
				{
					return "Next Monday";
				}
				else if(day == 2)
				{
					return "Next Tuesday";
				}
				else if(day == 3)
				{
					return "Next Wednesday";
				}
				else if(day == 4)
				{
					return "Next Thursday";
				}
				else if(day == 5)
				{
					return "Next Friday";
				}
				else if(day == 6)
				{
					return "Next Saturday";
				}
				else if(day == 0)
				{
					return "Next Sunday";
				}
			}
			else if(days >= 365)
			{
				return "Eventually";
			}
			else if(days >= 330)
			{
				return "11 Months";
			}
			else if(days >= 300)
			{
				return "10 Months";
			}
			else if(days >= 270)
			{
				return "9 Months";
			}
			else if(days >= 240)
			{
				return "8 Months";
			}
			else if(days >= 210)
			{
				return "7 Months";
			}
			else if(days >= 180)
			{
				return "6 Months";
			}
			else if(days >= 150)
			{
				return "5 Months";
			}
			else if(days >= 120)
			{
				return "4 Months";
			}
			else if(days >= 90)
			{
				return "3 Months";
			}
			else if(days >= 60)
			{
				return "2 Months";
			}
			else if(days > 30)
			{
				return "Next Month";
			}
			else if(days > 21)
			{
				return "3 Weeks";
			}
			else if(days > 14)
			{
				return "2 Weeks";
			}
			else if(days > 7)
			{
				return "Next Week";
			}
			else
			{
				return "";
			}
		},
		relativeDateCompact: function(input)
		{
			if(typeof input == "string")
			{
				input = new Date(input + "T09:00:00.000Z");
			}
			else if(input instanceof Date == false)
			{
				return "";
			}

			var d 		= new Date();
			var diff 	= (input.getTime() - d.getTime())/1000;
			var days 	= Math.ceil(diff/86400);
			var day 	= input.getDay();

			if(days < -1)
			{
				return days + "d";
			}
			else if(days == -1)
			{
				return "Yesterday";
			}
			else if(days == 0)
			{
				return "Today";
			}
			else if(days == 1)
			{
				return "Tomorrow";
			}
			else if(days < 7)
			{
				if(day == 1)
				{
					return "Mon";
				}
				else if(day == 2)
				{
					return "Tue";
				}
				else if(day == 3)
				{
					return "Wed";
				}
				else if(day == 4)
				{
					return "Thu";
				}
				else if(day == 5)
				{
					return "Fri";
				}
				else if(day == 6)
				{
					return "Sat";
				}
				else if(day == 0)
				{
					return "Sun";
				}
			}
			else if(days == 7)
			{
				if(day == 1)
				{
					return "Next Mon";
				}
				else if(day == 2)
				{
					return "Next Tue";
				}
				else if(day == 3)
				{
					return "Next Wed";
				}
				else if(day == 4)
				{
					return "Next Thu";
				}
				else if(day == 5)
				{
					return "Next Fri";
				}
				else if(day == 6)
				{
					return "Next Sat";
				}
				else if(day == 0)
				{
					return "Next Sun";
				}
			}
			else if(days >= 365)
			{
				return "∞";
			}
			else if(days >= 330)
			{
				return "11m";
			}
			else if(days >= 300)
			{
				return "10m";
			}
			else if(days >= 270)
			{
				return "9m";
			}
			else if(days >= 240)
			{
				return "8m";
			}
			else if(days >= 210)
			{
				return "7m";
			}
			else if(days >= 180)
			{
				return "6m";
			}
			else if(days >= 150)
			{
				return "5m";
			}
			else if(days >= 120)
			{
				return "4m";
			}
			else if(days >= 90)
			{
				return "3m";
			}
			else if(days >= 60)
			{
				return "2m";
			}
			else if(days > 30)
			{
				return "1m";
			}
			else if(days > 21)
			{
				return "3w";
			}
			else if(days > 14)
			{
				return "2w";
			}
			else if(days > 7)
			{
				return "1w";
			}
			else
			{
				return "";
			}
		},
		companyname: function(node)
		{
			if(typeof node.companyname == "undefined" || node.companyname == null || String(node.companyname).trim() == "")
			{
				return node.firstname + " " + node.lastname;
			}
			else
			{
				return node.companyname;
			}
		},
		cleandate(input)
		{
			if(input instanceof Date)
			{
				try
				{
					input = input.toISOString();
				}
				catch(e)
				{
					return input;
				}
			}

			if(typeof input == "undefined" || input == null || input == "0000-00-00 00:00:00" || input == "0000-00-00")
			{
				return "";
			}

			try
			{
				// The first two : are part of the date. For some reason EXIF data uses yyyy:mm:dd hh:mm:ss.
				input = input.replace(" ", "T").replace(/([0-9]{4}):([0-9]{2}):([0-9]{2})T(.*)/, "$1-$2-$3T$4");
			}
			catch(e)
			{
				return "";
			}

			return input;
		},
		number_format(number, decimals, dec_point, thousands_sep)
		{
			// http://kevin.vanzonneveld.net
			// +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
			// +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
			// +   bugfix by: Michael White (http://getsprink.com)
			// +   bugfix by: Benjamin Lupton
			// +   bugfix by: Allan Jensen (http://www.winternet.no)
			// +  revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
			// +   bugfix by: Howard Yeend
			// +  revised by: Luke Smith (http://lucassmith.name)
			// +   bugfix by: Diogo Resende
			// +   bugfix by: Rival
			// +    input by: Kheang Hok Chin (http://www.distantia.ca/)
			// +   improved by: davook
			// +   improved by: Brett Zamir (http://brett-zamir.me)
			// +    input by: Jay Klehr
			// +   improved by: Brett Zamir (http://brett-zamir.me)
			// +    input by: Amir Habibi (http://www.residence-mixte.com/)
			// +   bugfix by: Brett Zamir (http://brett-zamir.me)
			// +   improved by: Theriault
			// +    input by: Amirouche
			// +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
			// *   example 1: number_format(1234.56);
			// *   returns 1: '1,235'
			// *   example 2: number_format(1234.56, 2, ',', ' ');
			// *   returns 2: '1 234,56'
			// *   example 3: number_format(1234.5678, 2, '.', '');
			// *   returns 3: '1234.57'
			// *   example 4: number_format(67, 2, ',', '.');
			// *   returns 4: '67,00'
			// *   example 5: number_format(1000);
			// *   returns 5: '1,000'
			// *   example 6: number_format(67.311, 2);
			// *   returns 6: '67.31'
			// *   example 7: number_format(1000.55, 1);
			// *   returns 7: '1,000.6'
			// *   example 8: number_format(67000, 5, ',', '.');
			// *   returns 8: '67.000,00000'
			// *   example 9: number_format(0.9, 0);
			// *   returns 9: '1'
			// *  example 10: number_format('1.20', 2);
			// *  returns 10: '1.20'
			// *  example 11: number_format('1.20', 4);
			// *  returns 11: '1.2000'
			// *  example 12: number_format('1.2000', 3);
			// *  returns 12: '1.200'
			// *  example 13: number_format('1 000,50', 2, '.', ' ');
			// *  returns 13: '100 050.00'
			// Strip all characters but numerical ones.
			number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
			var n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
			dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
			s = '',
			toFixedFix = function (n, prec) {
				var k = Math.pow(10, prec);
				return '' + Math.round(n * k) / k;
			};
			// Fix for IE parseFloat(0.55).toFixed(0) = 0;
			s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
			if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
			}
			if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
			}
			return s.join(dec);
		},
		userHasAction(user, actions)
		{
			// God mode is automatically included in all checks.
			// If a user has God mode they can do anything.
			actions.push("IDDQD");

			if(user == null || typeof user.actions == "undefined" || user.actions == null)
			{
				return false;
			}

			for(var i=0; i<user.actions.length; i++)
			{
				for(var j=0; j<actions.length; j++)
				{
					if(user.actions[i].constant == actions[j])
					{
						return true;
					}
				}
			}

			return false;
		},
		loadTimerData: async function()
		{
			this.CORS("GET", "/timers", "", (response) => {
				this.$store.commit("timers", response);
			}, () => {
				this.$store.commit("timers", []);
			});

			this.CORS("GET", "/timers/modules", "", (response) => {
				this.$store.commit("timer_modules", response);
			}, () => {
				this.$store.commit("timer_modules", []);
			});

			this.CORS("GET", "/timers/clients", "", (response) => {
				this.$store.commit("timer_clients", response);
			}, () => {
				this.$store.commit("timer_clients", []);
			});
		}
	}
}
