import { createStore } from 'vuex'

const store = createStore({
	state:
	{
		timer_modules: [],
		timer_clients: [],
		timers: [],
		loading: 0,
		bg_loading: 0,
		dns_slaves: [],
	},
	getters:
	{
		timer_modules: state =>
		{
			return state.timer_modules;
		},
		timer_clients: state =>
		{
			return state.timer_clients;
		},
		timers: state =>
		{
			return state.timers;
		},
		loading: state =>
		{
			return state.loading > 0;
		},
		bg_loading: state =>
		{
			return state.bg_loading > 0;
		},
		dns_slaves: state =>
		{
			return state.dns_slaves;
		},
	},
	mutations:
	{
		timer_modules(state, timer_modules)
		{
			state.timer_modules = timer_modules;
		},
		timer_clients(state, timer_clients)
		{
			state.timer_clients = timer_clients;
		},
		timers(state, timers)
		{
			state.timers = timers;
		},
		loading(state)
		{
			state.loading++;
		},
		done(state)
		{
			state.loading--;

			if(state.loading < 0)
			{
				state.loading = 0;
			}
		},
		bg_loading(state)
		{
			state.bg_loading++;
		},
		bg_done(state)
		{
			state.bg_loading--;

			if(state.bg_loading < 0)
			{
				state.bg_loading = 0;
			}
		},
		dns_slaves(state, dns_slaves)
		{
			state.dns_slaves = dns_slaves;
		},
	}
});

export default store;
