<template>
	<div id="nerivonio">
		<header>
			<nav class="navbar navbar-expand-md navbar-light sticky-top mb-3" v-if="logged_in">
				<div class="collapse navbar-collapse" id="navbar">
					<ul class="nav w-100 d-flex">
						<li class="nav-item"><router-link class="nav-link" to="/" title="Dashboard"><em class="fal fa-lg fa-tachometer-alt"></em></router-link></li>
						<li class="nav-item" v-if="userHasAction(user, ['TASKS'])"><router-link class="nav-link" to="/today" title="Today"><em class="fal fa-lg fa-calendar-check"></em></router-link></li>
						<li class="nav-item" v-if="userHasAction(user, ['TASKS'])"><a class="nav-link" href="" @click.prevent="quickTask()" title="Quick Task"><em class="fal fa-lg fa-keyboard"></em></a></li>
						<li class="nav-item" v-if="userHasAction(user, ['TASKS'])"><router-link class="nav-link" to="/tasks" title="Tasks"><em class="fal fa-lg" :class="getRawIcon('task')"></em></router-link></li>
						<li class="nav-item" v-if="userHasAction(user, ['PROJECTS'])"><router-link class="nav-link" to="/projects" title="Projects"><em class="fal fa-lg" :class="getRawIcon('project')"></em></router-link></li>
						<li class="nav-item" v-if="userHasAction(user, ['NOTES'])"><router-link class="nav-link" to="/notes" title="Notes"><em class="fal fa-lg" :class="getRawIcon('note')"></em></router-link></li>
						<li class="nav-item" v-if="userHasAction(user, ['SNIPPETS'])"><router-link class="nav-link" to="/snippets" title="Code Snippets"><em class="fal fa-lg" :class="getRawIcon('snippet')"></em></router-link></li>
						<li class="nav-item" v-if="userHasAction(user, ['KB'])"><router-link class="nav-link" to="/kb" title="KB Articles"><em class="fal fa-lg" :class="getRawIcon('kb')"></em></router-link></li>
						<li class="nav-item" v-if="userHasAction(user, ['FILES'])"><router-link class="nav-link" to="/files" title="Files"><em class="fal fa-lg" :class="getRawIcon('file')"></em></router-link></li>
						<li class="nav-item" v-if="userHasAction(user, ['FILE_REQUESTS'])"><router-link class="nav-link" to="/file_requests" title="File Requests"><em class="fal fa-lg" :class="getRawIcon('file_request')"></em></router-link></li>
						<li class="nav-item" v-if="userHasAction(user, ['RCA'])"><router-link class="nav-link" to="/rca" title="Root Cause Analyses"><em class="fal fa-lg" :class="getRawIcon('rca')"></em></router-link></li>
						<li class="nav-item" v-if="userHasAction(user, ['CHECKLISTS'])"><router-link class="nav-link" to="/checklists" title="Checklists"><em class="fal fa-lg" :class="getRawIcon('checklist')"></em></router-link></li>
						<!-- <li class="nav-item"><router-link class="nav-link" to="/approvals" title="Approval Requests"><em class="fal fa-lg" :class="getRawIcon('approval')"></em></router-link></li> -->
						<li class="nav-item" v-if="userHasAction(user, ['CLIENTS'])"><router-link class="nav-link" to="/clients" title="Clients"><em class="fal fa-lg" :class="getRawIcon('client')"></em></router-link></li>
						<li class="nav-item" v-if="userHasAction(user, ['DNS'])"><router-link class="nav-link" to="/zones" title="DNS Zones"><em class="fal fa-lg" :class="getRawIcon('zone')"></em></router-link></li>
						<li class="nav-item" v-if="userHasAction(user, ['PASSWORDS'])"><router-link class="nav-link" to="/keyring/38729" title="Easy on Net Keyring"><em class="fal fa-lg" :class="getRawIcon('password')"></em></router-link></li>

						<li class="nav-item flex-fill">&nbsp;</li>
						<li class="nav-item"><a href="#" class="nav-link" title="Loading background data."><em v-if="bg_loading" class="fal fa-circle-notch fa-spin fa-lg" /></a></li>
						<li class="nav-item flex-fill">&nbsp;</li>

						<li class="nav-item dropdown">
							<router-link class="nav-link dropdown-toggle" data-toggle="dropdown" to="#" role="button" aria-haspopup="true" aria-expanded="false" title="Timers">
								<em class="fa-lg fa-stopwatch" :class="{'fas text-success': runningTimer, 'fal': !runningTimer}"></em>
							</router-link>
							<div class="dropdown-menu dropdown-menu-md-right">
								<router-link class="dropdown-item" to="/timers" @click.prevent=""><em class="fal fa-fw fa-stopwatch mr-2"></em>Timers</router-link>
								<div class="dropdown-divider"></div>
								<router-link class="dropdown-item" :to="'/timers/' + t.timer_id" v-for="t in timers">
									<div :class="{'text-success font-weight-bold': t.running}">
										<em class="fad fa-fw fa-sack-dollar text-success" v-if="t.billable == 1" title="This timer is billable."></em>
										{{t.task}}<br>
										<em class="text-muted">{{t.client_name}}</em>
									</div>
								</router-link>
							</div>
						</li>

						<li class="nav-item dropdown" v-if="userHasAction(user, ['REPORTS', 'BILLING', 'DNS_TOOLS', 'DNS_REPORTS'])">
							<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" title="Reports"><em class="fal fa-fw fa-list-alt"></em></a>
							<div class="dropdown-menu">
								<router-link v-if="userHasAction(user, ['REPORTS'])" to="/reports/activity" class="dropdown-item"><em class="fal fa-fw mr-2 fa-chart-scatter"></em> Activity</router-link>
								<router-link v-if="userHasAction(user, ['REPORTS'])" to="/reports/client" class="dropdown-item"><em class="fal fa-fw mr-2 fa-chart-line"></em> Clients</router-link>
								<router-link v-if="userHasAction(user, ['REPORTS'])" to="/reports/user" class="dropdown-item"><em class="fal fa-fw mr-2 fa-chart-pie-simple-circle-dollar"></em> Staff</router-link>
								<router-link v-if="userHasAction(user, ['BILLING'])" to="/reports/billing" class="dropdown-item"><em class="fal fa-fw mr-2 fa-sack-dollar"></em> Billing</router-link>
								<router-link v-if="userHasAction(user, ['BILLING'])" to="/reports/billed" class="dropdown-item"><em class="fal fa-fw mr-2 fa-file-invoice-dollar"></em> Billed Timers</router-link>
								<router-link v-if="userHasAction(user, ['REPORTS'])" to="/reports/timers" class="dropdown-item"><em class="fal fa-fw mr-2 fa-stopwatch"></em> Running Timers</router-link>
								<router-link v-if="userHasAction(user, ['DNS_REPORTS'])" class="dropdown-item" to="/zones/reports"><em class="fal fa-fw mr-2 fa-table"></em> DNS Reports</router-link>
								<router-link v-if="userHasAction(user, ['DNS_REPORTS'])" class="dropdown-item" to="/zones/status"><em class="fal fa-fw mr-2 fa-server"></em> DNS Cluster Report</router-link>
								<router-link v-if="userHasAction(user, ['DNS_TOOLS', 'DNS_REPORTS'])" class="dropdown-item" to="/zones/tools"><em class="fal fa-fw mr-2 fa-gear-complex-code"></em> DNS Tools</router-link>
							</div>
						</li>

						<li class="nav-item dropdown">
							<router-link class="nav-link dropdown-toggle" data-toggle="dropdown" to="#" role="button" aria-haspopup="true" aria-expanded="false" title="Nodes"><em class="fal fa-lg fa-sitemap"></em></router-link>
							<div class="dropdown-menu">
								<router-link v-if="userHasAction(user, ['TASKS'])" class="dropdown-item" to="/tasks"><em class="fal fa-fw mr-2" :class="getRawIcon('task')"></em> Tasks</router-link>
								<router-link v-if="userHasAction(user, ['PROJECTS'])" class="dropdown-item" to="/projects"><em class="fal fa-fw mr-2" :class="getRawIcon('project')"></em> Projects</router-link>
								<router-link v-if="userHasAction(user, ['NOTES'])" class="dropdown-item" to="/notes"><em class="fal fa-fw mr-2" :class="getRawIcon('note')"></em> Notes</router-link>
								<router-link v-if="userHasAction(user, ['SNIPPETS'])" class="dropdown-item" to="/snippets"><em class="fal fa-fw mr-2" :class="getRawIcon('snippet')"></em> Code Snippets</router-link>
								<router-link v-if="userHasAction(user, ['KB'])" class="dropdown-item" to="/kb"><em class="fal fa-fw mr-2" :class="getRawIcon('kb')"></em> KB Articles</router-link>
								<router-link v-if="userHasAction(user, ['FILES'])" class="dropdown-item" to="/files"><em class="fal fa-fw mr-2" :class="getRawIcon('file')"></em> Files</router-link>
								<router-link v-if="userHasAction(user, ['FILE_REQUESTS'])" class="dropdown-item" to="/file_requests"><em class="fal fa-fw mr-2" :class="getRawIcon('file_request')"></em> File Requests</router-link>
								<router-link v-if="userHasAction(user, ['RCA'])" class="dropdown-item" to="/rca"><em class="fal fa-fw mr-2" :class="getRawIcon('rca')"></em> Root Cause Analyses</router-link>
								<router-link v-if="userHasAction(user, ['CHECKLISTS'])" class="dropdown-item" to="/checklists"><em class="fal fa-fw mr-2" :class="getRawIcon('checklist')"></em> Checklists</router-link>
								<!-- <router-link class="dropdown-item" to="/approvals"><em class="fal fa-fw mr-2" :class="getRawIcon('approval')"></em> Approval Requests</router-link> -->
								<router-link v-if="userHasAction(user, ['CLIENTS'])" class="dropdown-item" to="/clients"><em class="fal fa-fw mr-2" :class="getRawIcon('client')"></em> Clients</router-link>
								<router-link v-if="userHasAction(user, ['DNS'])" class="dropdown-item" to="/zones"><em class="fal fa-fw mr-2" :class="getRawIcon('zone')"></em> DNS Zones</router-link>
								<router-link v-if="userHasAction(user, ['PASSWORDS'])" class="dropdown-item" to="/keyring/38729"><em class="fal fa-fw mr-2" :class="getRawIcon('password')"></em> Easy on Net Keyring</router-link>
							</div>
						</li>

						<li class="nav-item dropdown">
							<router-link class="nav-link dropdown-toggle" data-toggle="dropdown" to="#" role="button" aria-haspopup="true" aria-expanded="false" title="Create a Node"><em class="fal fa-lg fa-plus-circle"></em></router-link>
							<div class="dropdown-menu">
								<router-link v-if="userHasAction(user, ['TASKS_EDIT'])" class="dropdown-item" to="/node/new/task"><em class="fal fa-fw mr-2" :class="getRawIcon('task')"></em> Task</router-link>
								<router-link v-if="userHasAction(user, ['PROJECTS_EDIT'])" class="dropdown-item" to="/node/new/project"><em class="fal fa-fw mr-2" :class="getRawIcon('project')"></em> Project</router-link>
								<router-link v-if="userHasAction(user, ['NOTES_EDIT'])" class="dropdown-item" to="/node/new/note"><em class="fal fa-fw mr-2" :class="getRawIcon('note')"></em> Note</router-link>
								<router-link v-if="userHasAction(user, ['SNIPPETS_EDIT'])" class="dropdown-item" to="/node/new/snippet"><em class="fal fa-fw mr-2" :class="getRawIcon('snippet')"></em> Code Snippet</router-link>
								<router-link v-if="userHasAction(user, ['KB_EDIT'])" class="dropdown-item" to="/node/new/kb"><em class="fal fa-fw mr-2" :class="getRawIcon('kb')"></em> KB Article</router-link>
								<router-link v-if="userHasAction(user, ['FILES_EDIT'])" class="dropdown-item" to="/node/new/file"><em class="fal fa-fw mr-2" :class="getRawIcon('file')"></em> File</router-link>
								<router-link v-if="userHasAction(user, ['FILE_REQUESTS_EDIT'])" class="dropdown-item" to="/node/new/file_request"><em class="fal fa-fw mr-2" :class="getRawIcon('file_request')"></em> File Request</router-link>
								<router-link v-if="userHasAction(user, ['RCA_EDIT'])" class="dropdown-item" to="/node/new/rca"><em class="fal fa-fw mr-2" :class="getRawIcon('rca')"></em> Root Cause Analysis</router-link>
								<router-link v-if="userHasAction(user, ['CHECKLISTS_EDIT'])" class="dropdown-item" to="/node/new/checklist"><em class="fal fa-fw mr-2" :class="getRawIcon('checklist')"></em> Blank Checklist</router-link>
								<router-link v-if="userHasAction(user, ['CHECKLISTS_EDIT'])" class="dropdown-item" :to="'/bythread/use_checklist_template/' + t.node_id" v-for="t in checklist_templates" :key="t.node_id"><em class="fal fa-fw mr-2 fa-magic"></em> {{t.title}}</router-link>
								<!-- <router-link class="dropdown-item" to="/node/new/approval"><em class="fal fa-fw mr-2" :class="getRawIcon('approval')"></em> Approval Request</router-link> -->
								<router-link v-if="userHasAction(user, ['PASSWORDS_EDIT'])" class="dropdown-item" to="/node/new/password"><em class="fal fa-fw mr-2" :class="getRawIcon('password')"></em> Password</router-link>
								<a href="" v-if="userHasAction(user, ['DNS_EDIT'])" class="dropdown-item" @click.prevent="new_zone"><em class="fal fa-fw mr-2" :class="getRawIcon('zone')"></em> DNS Zone</a>
								<router-link v-if="userHasAction(user, ['TAGS_EDIT'])" class="dropdown-item" :to="'/tag_edit/0'"><em class="fal fa-fw mr-2 fa-tag"></em> Tag</router-link>
							</div>
						</li>

						<li class="nav-item dropdown" v-if="userHasAction(user, ['SAST', 'ZAP'])">
							<router-link class="nav-link dropdown-toggle" data-toggle="dropdown" to="#" role="button" aria-haspopup="true" aria-expanded="false" title="Guardian"><em class="fal fa-lg" :class="getRawIcon('guardian')"></em></router-link>
							<div class="dropdown-menu">
								<router-link v-if="userHasAction(user, ['ZAP'])" class="dropdown-item" to="/zap"><em class="fal fa-fw mr-2" :class="getRawIcon('zap')"></em> Website Security Scan</router-link>
								<router-link v-if="userHasAction(user, ['SAST'])" class="dropdown-item" to="/sast"><em class="fal fa-fw mr-2" :class="getRawIcon('sast')"></em> SAST Report</router-link>
								<router-link v-if="userHasAction(user, ['SAST'])" class="dropdown-item" to="/secrets"><em class="fal fa-fw mr-2" :class="getRawIcon('secrets')"></em> Secrets Detection Report</router-link>
								<router-link v-if="userHasAction(user, ['SAST'])" class="dropdown-item" to="/cms"><em class="fab fa-fw mr-2" :class="getRawIcon('cms')"></em> CMS Detection</router-link>
								<router-link v-if="userHasAction(user, ['SAST'])" class="dropdown-item" to="/cms"><em class="fab fa-fw mr-2" :class="getRawIcon('cms')"></em> CMS Vulnerability Scan</router-link>
								<router-link v-if="userHasAction(user, ['SBOM'])" class="dropdown-item" to="/sbom"><em class="fal fa-fw mr-2" :class="getRawIcon('sbom')"></em> Project Dependency Report (SBOM)</router-link>
								<router-link v-if="userHasAction(user, ['ZAP'])" class="dropdown-item" to="/guardian/queue"><em class="fal fa-fw mr-2" :class="getRawIcon('queue')"></em> Guardian Queue</router-link>
								<router-link v-if="userHasAction(user, ['ZAP'])" class="dropdown-item" to="/guardian/review"><em class="fal fa-fw mr-2" :class="getRawIcon('review')"></em> Guardian Review</router-link>
								<router-link v-if="userHasAction(user, ['ZAP'])" class="dropdown-item" to="/guardian/scans"><em class="fal fa-fw mr-2" :class="getRawIcon('scans')"></em> Guardian Scans</router-link>
							</div>
						</li>

						<li class="nav-item dropdown" v-if="userHasAction(user, ['USERS', 'ROLES', 'API', 'DNS_TOOLS'])">
							<router-link class="nav-link dropdown-toggle" data-toggle="dropdown" to="#" role="button" aria-haspopup="true" aria-expanded="false" title="Admin"><em class="fal fa-lg fa-cogs"></em></router-link>
							<div class="dropdown-menu">
								<router-link v-if="userHasAction(user, ['TAGS_EDIT'])" class="dropdown-item" to="/tags"><em class="fal fa-fw mr-2" :class="getRawIcon('tag')"></em> Tags</router-link>
								<router-link v-if="userHasAction(user, ['USERS'])" class="dropdown-item" to="/users"><em class="fal fa-fw mr-2" :class="getRawIcon('user')"></em> Users</router-link>
								<router-link v-if="userHasAction(user, ['ROLES'])" class="dropdown-item" to="/roles"><em class="fal fa-fw mr-2" :class="getRawIcon('role')"></em> User Roles</router-link>
								<router-link v-if="userHasAction(user, ['ROLES'])" class="dropdown-item" to="/role/0"><em class="fal fa-fw mr-2" :class="getRawIcon('role')"></em> New User Role</router-link>
								<a v-if="userHasAction(user, ['DNS_TOOLS'])" href="" class="dropdown-item" @click.prevent="dump_cache()"><em class="fal fa-fw fa-trash-alt mr-2"></em> Clear DNS Cache</a>
								<router-link v-if="userHasAction(user, ['API'])" class="dropdown-item" to="/rtfm"><em class="fal fa-fw mr-2" :class="getRawIcon('rtfm')"></em> API Documentation</router-link>
							</div>
						</li>

						<li class="nav-item"><router-link class="nav-link" to="/logout" title="Logout"><em class="fal fa-lg fa-fw fa-sign-out"></em></router-link></li>
					</ul>
				</div>

				<search />

				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>
			</nav>
			<nav class="navbar sticky-top mb-3 py-3" v-else>
				<img :src="retina('/img/eon-white.png')" style="width: 114px; height: 25px;" />
				<em class="fas fa-lg fa-lock-alt" title="Your connection is secure"></em>
			</nav>
		</header>

		<div :class="containerClass">
			<router-view :api_status="api_status" :ui_status="ui_status" :acl="acl" :user="user" :boards="boards" @logout="logout" />
		</div>

		<div class="toast" role="alert" aria-live="assertive" aria-atomic="true" id="toast" data-animation="true" data-autohide="true" data-delay="3000">
			<div class="toast-header">
				<strong class="mr-auto" id="toast_title"></strong>
				<small id="toast_subtitle"></small>
				<button type="button" class="ml-2 mb-1 close" data-dismiss="toast">&times;</button>
			</div>
			<div class="toast-body" id="toast_message"></div>
		</div>

		<input type="hidden" id="nvModalAnswer" />

		<div class="modal fade" id="zoneModal" tabindex="-1" role="dialog">
			<form @submit.prevent="_new_zone">
				<div class="modal-dialog modal-lg modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header"><h5 class="modal-title">New DNS Zone</h5></div>
						<div class="modal-body">
							<div class="form-row mb-2">
								<div class="col-4"><label for="zone_zone_name">Domain Name</label></div>
								<div class="col-8">
									<input type="text" class="form-control" id="new_zone_data.zone_name" v-model="new_zone_data.zone_name" required>
								</div>
							</div>
							<div class="form-row mb-2">
								<div class="col-4"><label>Type of Zone</label></div>
								<div class="col-8">
									<div class="btn-group" role="group">
										<button type="button" class="btn" :class="{'btn-primary':new_zone_data.kind == 'Master', 'btn-secondary':new_zone_data.kind == 'Slave'}" @click="new_zone_data.kind='Master'">Master</button>
										<button type="button" class="btn" :class="{'btn-primary':new_zone_data.kind == 'Slave', 'btn-secondary':new_zone_data.kind == 'Master'}" @click="new_zone_data.kind='Slave'">Slave</button>
									</div>
								</div>
							</div>
							<div class="form-row mb-2" v-if="new_zone_data.kind == 'Slave'">
								<div class="col-4"><label for="zone_slave_preset">Common Slaves</label></div>
								<div class="col-8">
									<select class="form-control custom-select" id="zone_slave_preset" v-model="new_zone_data.slave_preset" @change="new_zone_data.master = new_zone_data.slave_preset">
										<option :value="null">Choose a Server</option>
										<option v-for="slave in dns_slaves" :value="slave.ip">{{slave.name}}<span v-if="slave.sync == 1"> (sync enabled)</span></option>
									</select>
								</div>
							</div>
							<div class="form-row mb-2" v-if="new_zone_data.kind == 'Slave'">
								<div class="col-4"><label for="zone_master">IP Address</label></div>
								<div class="col-8"><input type="text" class="form-control" id="zone_master" v-model="new_zone_data.master" @change="new_zone_data.slave_preset = null" required /></div>
							</div>
						</div>
						<div class="modal-footer">
							<div class="d-flex justify-content-between w-100">
								<button type="button" class="btn btn-secondary" data-dismiss="modal"><em class="fal fa-times-circle"></em> Close</button>
								<button type="submit" class="btn btn-primary"><em class="fal fa-book-atlas"></em> Create Zone</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import { defineAsyncComponent} from 'vue';
	import jQuery from 'jquery'
	import { mapGetters } from 'vuex';

	export default
	{
		name: 'App',
		inheritAttrs: false,
		head() {
			return {
				title: "Nerivon I/O",
				titleTemplate: '%s | Nerivon I/O'
			}
		},
		components:
		{
			'search': defineAsyncComponent(() => import('./components/Search.vue'))
		},
		data() {
			return {
				acl: [],
				api_status: {"version": "", "changelog": ""},
				ui_status: {"version": "", "changelog": ""},
				logged_in: (this.getCookie("access_token_cookie") != ""),
				recording: false,
				uploading: false,
				checklist_templates: [],
				user: null,
				client: null,
				boards: [],
				interval: null,
				public_urls: [
					/^\/login$/,
					/^\/upload\/.*$/
				],
				new_zone_data: {
					zone_name: "",
					kind: "Master",
					slave_preset: null,
					master: "",
				}
			}
		},
		computed: {
			containerClass: function()
			{
				if(this.$route.path == "/login" || this.$route.path.substring(0, 7) == "/upload")
				{
					return "container";
				}
				else
				{
					return "container-fluid";
				}
			},
			runningTimer() {
				for(let i=0; i<this.timers.length; i++) {
					if(this.timers[i].running) {
						return true;
					}
				}

				return false;
			},
			...mapGetters([
				"timers",
				"bg_loading",
				"dns_slaves",
			]),
		},
		methods: {
			checkSession: function()
			{
				var vm = this;

				vm.CORS("GET", "/authenticate", "", function(response)
				{
					if(response.user) {
						vm.user = response.user;

						for(var i=0; i<vm.user.roles.length; i++)
						{
							if(!vm.acl.includes(vm.user.roles[i]["constant"]))
							{
								vm.acl.push(vm.user.roles[i]);
							}
						}
					}
					else if(response.client) {
						vm.client = response.client;
					}
					vm.logged_in = true;
				},
				function()
				{
					// vm.logged_in = false;
					// vm.user = null;
					// vm.$router.push("/login").catch(function() { /* ignore error */ });
				});
			},
			loadBoards: function()
			{
				var vm = this;

				vm.CORS("GET", "/boards/0", "", function(response)
				{
					vm.boards = response;
				},
				function()
				{
					vm.boards = [];
				});
			},
			loadSlaves: function()
			{
				this.CORS("GET", "/zones/slaves", "", (response) => {
					this.$store.commit("dns_slaves", response);
				},
				() => {
					this.$store.commit("dns_slaves", []);
				});
			},
			logout: function()
			{
				this.logged_in = false;
			},
			getStatus: function()
			{
				// var vm = this;

				// jQuery.ajax(
				// {
				// 	type: "GET",
				// 	url: "/CHANGELOG.md",
				// 	data: null,
				// }).done(function(data)
				// {
				// 	var converter = new showdown.Converter();

				// 	var re = /^## ([0-9]+\.[0-9]+\.[0-9]+ .*)$/gm;
				// 	var m = re.exec(data);
				// 	// var p = m[1]
				// 	var parts = m[1].split(" ")
				// 	var start = data.indexOf(m[0])

				// 	vm.ui_status.version = parts[0]
				// 	vm.ui_status.changelog = data.substring(start);
				// 	vm.ui_status.changelog = converter.makeHtml(vm.ui_status.changelog);
				// }).fail(function()
				// {
				// 	vm.showError("Error", "Could not get UI version information.", true, null);
				// });

				// vm.CORS('GET', "/status", null,
				// 	function(data)
				// 	{
				// 		var converter = new showdown.Converter();

				// 		vm.api_status = data;
				// 		vm.api_status.changelog = converter.makeHtml(vm.api_status.changelog);
				// 	},
				// 	function()
				// 	{
				// 		vm.showError("API Error", "The API server appears to be down.", true, null);
				// 	});
			},
			checkPath: function(path)
			{
				var vm = this;

				// Don't check security on public URLs.
				for(var i=0; i<vm.public_urls.length; i++)
				{
					if(path.match(vm.public_urls[i]) !== null)
					{
						return;
					}
				}

				vm.checkSession();
				vm.loadBoards();
				vm.loadSlaves();
				vm.loadTimerData();
				vm.getStatus();

				if(vm.interval != null)
				{
					clearInterval(vm.interval);
				}

				vm.interval = setInterval(function()
				{
					vm.checkSession();
					vm.loadBoards();
					vm.loadSlaves();
					vm.getStatus();
				}, 60000);
			},
			new_zone: function() {
				jQuery("#zoneModal").modal("show");
			},
			_new_zone: function() {
				jQuery("#zoneModal").modal("hide");
				this.$store.commit("loading");
				// Remove http:// and https://
				this.new_zone_data.zone_name = this.new_zone_data.zone_name.replaceAll(/https?:\/\//gi, "");
				// Remove any slashes.
				this.new_zone_data.zone_name = this.new_zone_data.zone_name.replaceAll(/\//g, "");

				this.CORS("POST", "/zones", JSON.stringify(this.new_zone_data),
					(response) => {
						this.new_zone_data = {
							zone_name: "",
							kind: "Master",
							slave_preset: null,
							master: "",
						};
						this.$store.commit("done");
						this.$router.push("/zone/" + response.name.replace('/', '=2F'))
					}, (response) => {
						this.$store.commit("done");
						this.showError("Error", response.responseJSON, true, () => {
							jQuery("#zoneModal").modal("show");
						});
					});
			},
			dump_cache: function() {
				this.CORS("DELETE", "/zones/cache", null,
					(response) => {
						this.$store.commit("done");
						this.showSuccess("DNS Caches Cleared", "The internal DNS caches were cleared.", true, null);
					}, (response) => {
						this.$store.commit("done");
						this.showError("Error", response.responseJSON, true, null);
					});
			}
		},
		mounted: function()
		{
			this.checkPath(window.location.pathname);
		},
		watch:
		{
			'$route.path' (to, from)
			{
				if(to == from) {
					return;
				}

				this.checkPath(this.$route.path);
			}
		}
	}
</script>
