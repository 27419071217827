import { createRouter, createWebHistory } from 'vue-router'
import jQuery from 'jquery'

const routes = [
	{ path: '/', name: 'Dashboard', component: () => import('../views/Dashboard.vue') },
	{ path: '/today', component: () => import('../views/Today.vue') },
	{ path: '/login', component: () => import('../views/Login.vue') },
	{ path: '/logout', component: () => import('../views/Logout.vue') },
	{ path: '/changelog', component: () => import('../views/Changelog.vue') },
	{ path: '/users', component: () => import('../views/Users.vue') },
	{ path: '/roles', component: () => import('../views/Roles.vue') },
	// { path: '/role', component: () => import('../views/Role.vue') },
	{ path: '/role/:acl_role_id', component: () => import('../components/form/Role.vue') },
	{ path: '/keyring', component: () => import('../views/Keyring.vue') },
	{ path: '/keyring/:client_node_id', component: () => import('../views/Keyring.vue') },
	{ path: '/tag/:tag_id', component: () => import('../views/Tag.vue') },
	{ path: '/node/:node_id', component: () => import('../views/Node.vue') },
	{ path: '/node/new/:node_type', component: () => import('../views/Node.vue') },
	{ path: '/node/new/:node_type/:client_node_id', component: () => import('../views/Node.vue') },
	{ path: '/node/new/:node_type/:client_node_id/:project_node_id', component: () => import('../views/Node.vue') },
	{ path: '/notes', component: () => import('../views/Notes.vue') },
	{ path: '/notes/:client_node_id', component: () => import('../views/Notes.vue') },
	{ path: '/rca', component: () => import('../views/RCAs.vue') },
	{ path: '/rca/:client_node_id', component: () => import('../views/RCAs.vue') },
	{ path: '/snippets', component: () => import('../views/Snippets.vue') },
	{ path: '/snippets/:client_node_id', component: () => import('../views/Snippets.vue') },
	{ path: '/kb', component: () => import('../views/KB.vue') },
	{ path: '/projects', component: () => import('../views/Projects.vue') },
	{ path: '/projects/:client_node_id', component: () => import('../views/Projects.vue') },
	{ path: '/tasks', component: () => import('../views/Tasks.vue') },
	{ path: '/tasks/:client_node_id', component: () => import('../views/Tasks.vue') },
	{ path: '/approvals', component: () => import('../views/Approvals.vue') },
	{ path: '/approvals/:client_node_id', component: () => import('../views/Approvals.vue') },
	{ path: '/checklists', component: () => import('../views/Checklists.vue') },
	{ path: '/files', component: () => import('../views/Files.vue') },
	{ path: '/files/:client_node_id', component: () => import('../views/Files.vue') },
	{ path: '/file_requests', component: () => import('../views/FileRequests.vue') },
	{ path: '/file_requests/:client_node_id', component: () => import('../views/FileRequests.vue') },
	{ path: '/search/:q', component: () => import('../views/Search.vue') },
	{ path: '/clients', component: () => import('../views/Clients.vue') },
	{ path: '/zones', component: () => import('../views/Zones.vue') },
	{ path: '/zones/status', component: () => import('../views/ZoneStatus.vue') },
	{ path: '/zones/tools', component: () => import('../views/ZoneTools.vue') },
	{ path: '/zones/reports', component: () => import('../views/ZoneReports.vue') },
	{ path: '/zones/reports/:report_index', component: () => import('../views/ZoneReports.vue') },
	{ path: '/zone/:zone_id', component: () => import('../views/Zone.vue') },
	{ path: '/upload/:token', component: () => import('../views/public/FileRequest.vue') },
	{ path: '/zap', component: () => import('../views/guardian/ZAP.vue') },
	{ path: '/sbom', component: () => import('../views/guardian/SBOM.vue') },
	{ path: '/sast', component: () => import('../views/guardian/SAST.vue') },
	{ path: '/secrets', component: () => import('../views/guardian/Secrets.vue') },
	{ path: '/cms', component: () => import('../views/guardian/CMS.vue') },
	{ path: '/guardian/queue', component: () => import('../views/guardian/Queue.vue') },
	{ path: '/guardian/scans', component: () => import('../views/guardian/History.vue') },
	{ path: '/guardian/review', component: () => import('../views/guardian/Review.vue') },
	{ path: '/rtfm', component: () => import('../views/RTFM.vue') },
	{ path: '/tags', component: () => import('../views/Tags.vue') },
	{ path: '/tag_edit/:tag_id', component: () => import('../views/TagEdit.vue') },
	{ path: '/timers', component: () => import('../views/Timers.vue') },
	{ path: '/timers/:timer_id', component: () => import('../views/Timer.vue') },
	{ path: '/reports/activity', component: () => import('../views/reports/Activity.vue') },
	{ path: '/reports/billing', component: () => import('../views/reports/Billing.vue') },
	{ path: '/reports/billed', component: () => import('../views/reports/Billed.vue') },
	{ path: '/reports/client', component: () => import('../views/reports/Client.vue') },
	{ path: '/reports/user', component: () => import('../views/reports/User.vue') },
	{ path: '/reports/timers', component: () => import('../views/reports/Timers.vue') },
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

// Hide the mobile menu every time a navigation occurs.
router.beforeEach((to, from, next) => {
	jQuery('#navbar').removeClass('show');

	// Remove any existing popovers.
	var elements = document.getElementsByClassName("popover");

	// Convert HTMLCollection to an array to avoid live collection issues.
	elements = Array.from(elements);

	// Loop through the elements and remove each one.
	elements.forEach(function(element) {
		element.remove();
	});

	next();
});

export default router
