import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/js/bootstrap.js'
import jQuery from 'jquery'
import '@fortawesome/fontawesome-pro/css/all.css'
import * as Sentry from '@sentry/vue';
import mixit from './mixins/default.js';
import VueHighlightJS from 'vue3-highlightjs';
import 'highlight.js/styles/monokai-sublime.css';
import { VueHeadMixin, createHead } from '@unhead/vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import store from './store/index.js';

Array.prototype.unique = function()
{
	return this.filter(function (value, index, self)
	{
		return self.indexOf(value) === index;
	});
}

const app = createApp(App, {
	router,
	store,
});
const head = createHead();

Sentry.init({
	app,
	dsn: "https://d630c82b58651e24bbd2daaf206a81fc@o4505835419074560.ingest.sentry.io/4505845605597184",
	environment: (String(window.location).indexOf("https://manage.easyonnet.io") !== -1 ? "production" : "development"),
	// Performance Monitoring
	tracesSampleRate: 0.75, // Capture 100% of the transactions, reduce in production!
	// This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production.
	replaysSessionSampleRate: 0.1,
	// If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: [/^https:\/\/manage\.easyonnet\.io/],
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Replay()
	],
});

// app.use(store);
app.use(head)
app.mixin(VueHeadMixin)
app.use(router);
app.use(store);
app.use(VueHighlightJS);
app.use(CKEditor);
app.mixin(mixit);
app.mount("#app");
import VueDiff from 'vue-diff';
import 'vue-diff/dist/index.css';
app.use(VueDiff);
